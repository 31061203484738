export default [
  {
    header: 'Secciones del sistema',
  },
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard-analytics',
  },
]
